import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  ModalCloseButton,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';

import BlackCheck from '../../../../Assets/Images/check-black-fill.svg';
import formatCentsToEur from '../../../../Utils/formatCentsToEur';

import IsComingForm from '../../../IsComingForm/IsComingForm';

export default function ChallengeItemModalConfirmation({
  isMission,
  userCanParticipateInMissions,
  userData,
  moneyToClaim,
  hasAcceptedConditions,
  toggleHasAcceptedConditions,
  confirmationModalAction,
  confirmationModalButtonActionText,
  isOpenConfirmationModal,
  onCloseConfirmationModal,
  actionToConfirm,
  isComingSoon,
}) {
  // Cambiamos el modal en función del tipo de acción a confirmar (actionToConfirm)

  // Acción de participar por defecto
  let modalContent = (
    <Box fontFamily="DM Sans">
      <Text fontWeight="bold" fontSize="20px" mb={2}>
        Participar en este reto
      </Text>
      <Text fontSize="16px" lineHeight={1.3}>
        Cosas que debes tener en cuenta cuando participas en un reto:
      </Text>
      <Flex mt="12px" flexDir="column">
        <Flex mb="12px">
          <Box mr={2} width="20px" height="20px" minW="20px">
            1️⃣
          </Box>
          <Text fontSize="14px" lineHeight={1.3}>
            <strong>Tienes 72 horas para subir el vídeo desde que participas</strong>. Pasado este
            tiempo tu plaza será liberada. La podrá coger otro creador o tú de nuevo.
          </Text>
        </Flex>
        <Flex mb="12px">
          <Box mr={2} width="20px" height="20px" minW="20px">
            2️⃣
          </Box>
          <Text fontSize="14px" lineHeight={1.3}>
            <strong>La marca no tiene que confirmar tu participación</strong>. Cuando participas ya
            estás dentro de la campaña. El siguiente paso es subir el vídeo.
          </Text>
        </Flex>
        <Flex mb="24px">
          <Box mr={2} width="20px" height="20px" minW="20px">
            3️⃣
          </Box>
          <Text fontSize="14px" lineHeight={1.3}>
            <strong>No hay envío de producto</strong>. En ningún reto se envía producto. Si es una
            app sólo tienes que descargarla. Si es un producto el dinero para la compra se incluye
            en la recompensa fija.
          </Text>
        </Flex>
        <Flex mb="12px" onClick={toggleHasAcceptedConditions}>
          <Box
            cursor="pointer"
            border="2px solid"
            mr={2}
            width="20px"
            height="20px"
            minW="20px"
            borderRadius="50%"
            backgroundRepeat="no-repeat"
            backgroundSize="10px"
            backgroundPosition="center"
            backgroundColor="#EBD0FF"
            backgroundImage={`url(${hasAcceptedConditions ? BlackCheck : ''})`}
          />
          <Text fontSize="14px" lineHeight={1.3}>
            Confirmo que subiré el video durante las próximas 72 horas o mi perfil podrá ser
            penalizado.
          </Text>
        </Flex>
      </Flex>
      <Button
        onClick={() => confirmationModalAction()}
        mt={2}
        border="2px solid black"
        boxShadow="1px 1px 0px 0px #000"
        borderRadius="16px"
        bg="#EBD0FF"
        color="black"
        width="100%"
      >
        {confirmationModalButtonActionText}
      </Button>
    </Box>
  );

  if (actionToConfirm === 'participate' && isMission) {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          Participar en esta misión
        </Text>
        <Text fontSize="16px" lineHeight={1.3}>
          Cosas que debes tener en cuenta cuando participas en una misión.
        </Text>
        <Flex mt="12px" flexDir="column">
          <Flex mb="12px">
            <Box mr={2} width="20px" height="20px" minW="20px">
              1️⃣
            </Box>
            <Text fontSize="14px" lineHeight={1.3}>
              <strong>Tienes 72 horas para subir el vídeo desde que participas</strong>. Pasado este
              tiempo tu plaza será liberada. La podrá coger otro creador o tú de nuevo.
            </Text>
          </Flex>
          <Flex mb="12px">
            <Box mr={2} width="20px" height="20px" minW="20px">
              2️⃣
            </Box>
            <Text fontSize="14px" lineHeight={1.3}>
              <strong>La marca no tiene que confirmar tu participación</strong>. Cuando participas
              ya estás dentro de la campaña. El siguiente paso es subir el vídeo.
            </Text>
          </Flex>
          <Flex mb="24px">
            <Box mr={2} width="20px" height="20px" minW="20px">
              3️⃣
            </Box>
            <Text fontSize="14px" lineHeight={1.3}>
              <strong>No hay envío de producto</strong>. En ninguna misión se envía producto. Si es
              una app sólo tienes que descargarla. Si es un producto el dinero para la compra se
              incluye en la recompensa fija.
            </Text>
          </Flex>
          <Flex mb="12px" onClick={toggleHasAcceptedConditions}>
            <Box
              cursor="pointer"
              border="2px solid"
              mr={2}
              width="20px"
              height="20px"
              minW="20px"
              borderRadius="50%"
              backgroundRepeat="no-repeat"
              backgroundSize="10px"
              backgroundPosition="center"
              backgroundColor="#EBD0FF"
              backgroundImage={`url(${hasAcceptedConditions ? BlackCheck : ''})`}
            />
            <Text fontSize="14px" lineHeight={1.3}>
              Confirmo que subiré el video durante las próximas 72 horas o mi perfil podrá ser
              penalizado.
            </Text>
          </Flex>
        </Flex>
        <Button
          onClick={() => confirmationModalAction()}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          {confirmationModalButtonActionText}
        </Button>
      </Box>
    );
  }

  // si es misión cambia el texto
  if (actionToConfirm === 'unparticipate') {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          Dejar de participar
        </Text>
        <Flex mt="12px" flexDir="column">
          <Flex mb="12px">
            <Text fontSize="14px" lineHeight={1.3}>
              Vas a dejar de participar en {isMission ? 'esta misión' : 'este reto'}, ¿estás seguro
              de tu acción?
            </Text>
          </Flex>
        </Flex>
        <Button
          onClick={() => confirmationModalAction()}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          {confirmationModalButtonActionText}
        </Button>
      </Box>
    );
  }

  if (actionToConfirm === 'notifyme') {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          ¿Te notificamos?
        </Text>

        <Flex mt="12px" flexDir="column">
          <Flex mb="12px">
            <Text fontSize="14px" lineHeight={1.3}>
              Cada día a las 10:00 se liberan plazas y te notificaremos por email. Tendrás que
              entrar y darle a participar. ¡Sé rápidx!
            </Text>
          </Flex>
        </Flex>
        <Button
          onClick={() => confirmationModalAction()}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          {confirmationModalButtonActionText}
        </Button>
      </Box>
    );
  }

  if (isComingSoon) {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          Apúntate al reto
        </Text>
        <IsComingForm userData={userData} />
        <Button
          onClick={() => confirmationModalAction()}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          {confirmationModalButtonActionText}
        </Button>
      </Box>
    );
  }

  if (actionToConfirm === 'claim') {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          Reclama tus {formatCentsToEur(moneyToClaim)}
        </Text>

        <Flex mt="12px" flexDir="column">
          <Flex mb="12px">
            <Text fontSize="14px" lineHeight={1.3}>
              Has conseguido <strong>{formatCentsToEur(moneyToClaim)}</strong> completando este
              reto. El vídeo tiene que estar público un mínimo de 30 días. Por ello, la recompensa
              de este reto se incluirá en &quot;saldo pendiente&quot;. Pasados esos 30 días, podrás
              incluirlo en tu saldo disponible para retirar.
            </Text>
          </Flex>
        </Flex>
        <Button
          onClick={() => confirmationModalAction()}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          {confirmationModalButtonActionText}
        </Button>
      </Box>
    );
  }

  if (isMission === true && userCanParticipateInMissions === false) {
    modalContent = (
      <Box fontFamily="DM Sans">
        <Text fontWeight="bold" fontSize="20px" mb={2}>
          No puedes participar :(
        </Text>

        <Flex mt="12px" flexDir="column">
          <Flex mb="12px">
            <Text fontSize="14px" lineHeight={1.3}>
              No cumples los requisitos para participar en las misiones. Actualmente sólo los
              creadores con más de{' '}
              <strong>5.000 seguidores pueden participar en las misiones</strong>.
            </Text>
          </Flex>
        </Flex>
      </Box>
    );
  }

  return (
    <Modal
      isCentered
      isOpen={isOpenConfirmationModal}
      onClose={onCloseConfirmationModal}
      closeOnOverlayClick
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent border="2px solid #EBD0FF" borderRadius="24px" m={5}>
        <ModalCloseButton bg="#fff" borderRadius="50%" m={1} border="1px solid" />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
          {modalContent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
