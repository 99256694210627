/* eslint-disable no-console */
/* eslint-disable no-useless-return */
/* eslint-disable no-undef */

import { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';

function IsComingForm({ userData }) {
  const [formSubmitted, setFormSubmitted] = useState(false);

  // For embeds/popups
  useEffect(() => {
    const handleMessage = (e) => {
      if (e?.data?.includes('Tally.FormSubmitted')) {
        const { payload } = JSON.parse(e.data);
        console.log(payload);
        setFormSubmitted(true); // Set formSubmitted to true when form is submitted
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // The code below will load the embed
  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

    const load = () => {
      // Load Tally embeds
      if (typeof Tally !== 'undefined') {
        Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document.querySelectorAll('iframe[data-tally-src]:not([src])').forEach((iframeEl) => {
        iframeEl.src = iframeEl.dataset.tallySrc;
      });
    };

    // If Tally is already loaded, load the embeds
    if (typeof Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    }
  }, []);

  return (
    <Box maxW="100%">
      <iframe
        data-tally-src={`https://tally.so/embed/wb2p67?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&userId=${userData?.id}&tikTokUser=${userData?.tikTok}&phone=${userData?.phone}&followers=${userData?.tikTokInfo?.followerCount}`}
        loading="lazy"
        width="100%"
        height="574"
        title="Reto Micolet - BlackFriday"
        style={{ position: 'relative' }}
      />

      {/* Change the Box background color when form is submitted */}
      <Box
        bottom={formSubmitted ? 115 : 83}
        right={formSubmitted ? 0 : 19}
        position="absolute"
        width={formSubmitted ? '100%' : '50px'}
        margin="0 auto"
        height="40px"
        bg="#fffff2"
      />
    </Box>
  );
}

export default IsComingForm;
