/* eslint-disable no-console */
import React from 'react';

import { Box, Flex, Button, Text, useDisclosure } from '@chakra-ui/react';
import { FaTiktok } from 'react-icons/fa';

import VideoModal from '../VideoModal/VideoModal';
import ConnectTikTokModal from '../ConnectTikTokModal/ConnectTikTokModal';

import './TikTokMissionItem.css';

export default function TikTokMissionItem({
  connectionError,
  isOpenTikTokModal,
  openTikTokModal,
  closeTikTokModal,
  hasExpiredSession,
}) {
  const {
    isOpen: isOpenVideoModal,
    onOpen: onOpenVideoModal,
    onClose: onCloseVideoModal,
  } = useDisclosure();

  const handleClickConnectTikTok = () => {
    openTikTokModal();
  };

  return (
    <Box border="2px solid black" borderRadius="16px" boxShadow="2px 2px 0px 0px #000" bg="fffff2">
      <Box textAlign="center" paddingBottom={4} paddingTop={5} paddingRight={4} paddingLeft={4}>
        <Box display="flex" pb={2} justifyContent="center" alignItems="center" margin="0 auto">
          <FaTiktok fontSize={25} />
        </Box>
        <Text fontFamily="DM Sans" pb={2} fontSize="12px" maxW="350px" m="0 auto">
          {hasExpiredSession
            ? 'Tu sesión de TikTok ha expirado, conéctalo de nuevo para seguir participando en las misiones.'
            : 'Conecta tu TikTok para participar en los retos y misiones. Necesitas tener un mínimo de 5k seguidores.'}
        </Text>
        <Button
          onClick={() => handleClickConnectTikTok()}
          fontFamily="DM Sans"
          size="xs"
          variant="solid"
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="8px"
          bg="#EBD0FF"
          color="black"
          paddingX="14px"
          paddingY="12px"
        >
          Conectar TikTok
        </Button>
        <Flex justifyContent="center">
          <Button
            marginTop="5px"
            fontFamily="DM Sans"
            height="auto"
            fontSize="12px"
            display="block"
            textDecoration="underline"
            fontWeight="medium"
            variant="unstyled"
            size="xs"
            onClick={() => {
              onOpenVideoModal();
            }}
          >
            Ver tutorial
          </Button>
        </Flex>
        <VideoModal
          videoSrc="https://www.youtube.com/shorts/cCjEc7QuiU4"
          isOpen={isOpenVideoModal}
          close={onCloseVideoModal}
        />
        <ConnectTikTokModal
          connectionError={connectionError}
          open={openTikTokModal}
          isOpen={isOpenTikTokModal}
          close={closeTikTokModal}
        />
      </Box>
    </Box>
  );
}
