export const getDiffInHours = (start, end) => {
  const timeDifference = end.getTime() - start.getTime();
  return timeDifference / (1000 * 3600);
};

export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function formatDateToDayMonth(dateString) {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, '0'); // Ensure two digits
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  return `${day}/${month}`;
}
