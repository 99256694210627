import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import './ChallengeItemModalDescription.css';

export default function ChallengeItemModalDescription({ isMission, description, challenge }) {
  const { isInstagram, isStory } = challenge;

  let platformText =
    'Este es un reto para TikTok, por lo que debes subir el vídeo a tu cuenta de TikTok conectada.';

  if (isInstagram) {
    platformText =
      'Este es un reto para Instagram Reels, por lo que debes subir el vídeo a tu cuenta de Instagram conectada.';
  }

  if (isInstagram && isStory) {
    platformText =
      'Este es un reto para Instagram Stories, por lo que debes subir la historia a tu cuenta de Instagram conectada.';
  }

  return (
    <Box p="0px 20px 20px 20px">
      <Text fontFamily="DM Sans" fontSize="20px" fontWeight="bold" mb={4}>
        {isMission ? 'Misión' : 'Reto'} {challenge?.title}
      </Text>

      <Text fontFamily="DM Sans" mb={1} fontWeight="bold">
        Plataforma
      </Text>
      <Box mb={2}>
        <Text fontFamily="DM Sans" fontSize="14px">
          {platformText}
        </Text>
      </Box>

      <Text fontFamily="DM Sans" mb={2} fontWeight="bold">
        Descripción {isMission ? 'de la misión' : 'del reto'}
      </Text>
      <div className="description-html">
        <Box
          fontFamily="DM Sans"
          fontSize="14px"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </Box>
  );
}
